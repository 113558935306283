<script>
	import CaseStudy from '$lib/Components/CaseStudy/CaseStudy.svelte';
	import Grid from '$lib/Components/Layout/Grid/Grid.svelte';
	import Section from '$lib/Components/Layout/Section/Section.svelte';

	import bigfoot from './images/Bigfoot-Profile-square.jpg?w=250';
	import pickleball from './images/pickeball-thumb.jpg?w=250';
	import taxCredits from './images/taxcredits.jpg?w=250';

	export let loading = 'lazy';
	export let hideMobile = 'false'; //Defualt behavior to hide on mobile view.
	export let backgroundColor = 'blue';
</script>

<Section {backgroundColor} title="Featured Articles" {hideMobile}>
	<Grid columns="3" rows="3" center class="gap-x-8">
		<CaseStudy
			subgrid
			title="SAVE MONEY"
			src={taxCredits}
			href="/resources/blog/energy-tax-credits-window-inserts"
			{loading}
		>
			<p slot="subtitle" class="text-balance">
				How to Get Energy Tax Credits with Window Inserts
			</p>
		</CaseStudy>
		<CaseStudy
			subgrid
			title="CREATURE COMFORTS"
			src={bigfoot}
			href="/bigfoot"
			{loading}
		>
			<p slot="subtitle" class="text-balance">
				See How Bigfoot Blocks Out Noisy Neighbors
			</p>
		</CaseStudy>
		<CaseStudy
			subgrid
			title="PICKLEBALL NOISE"
			src={pickleball}
			href="/resources/blog/block-pickleball-noise"
			{loading}
		>
			<p slot="subtitle" class="text-balance">
				Acoustic Inserts Shut Out Pickleball's Painful Pounding
			</p>
		</CaseStudy>
	</Grid>
	<p>
		<a class="font-bold" href="/resources/featured-articles">VIEW MORE</a>
	</p>
</Section>
